interface NextButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  nextDisabled?: boolean;
  backDisabled?: boolean;
  onNext: () => void;
  onBack: () => void;
}

const NextButton: React.FC<NextButtonProps> = ({
  children,
  nextDisabled,
  backDisabled,
  onBack,
  onNext,
  ...props
}) => {
  return (
    <>
      <div className="next-wrapper">
        <button
          className="next-button"
          {...props}
          onClick={onNext}
          disabled={nextDisabled}
        >
          {children || "Weiter"}
        </button>
        <div className="back-wrapper">
          <button
            className="back-button"
            {...props}
            onClick={onBack}
            disabled={backDisabled}
          >
            Zurück
          </button>
        </div>
      </div>
    </>
  );
};

export default NextButton;
