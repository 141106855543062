import sortByField from "../functions/sortByField";

const fetchStores = async () => {
  try {
    const raw = await fetch(
      `https://ipyzqcvg36.execute-api.eu-central-1.amazonaws.com/prod/store`
    );

    if (raw.status > 299) return { data: [], error: true };

    const result = await raw.json();

    return {
      data: result?.response
        .sort((a: any, b: any) => sortByField(a, b, "name"))
        .filter((item: any) => !item.closed),
      error: false,
    };
  } catch (err) {
    console.log(err);
    return { data: [], error: true };
  }
};

export default fetchStores;
