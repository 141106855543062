import { useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import NextButton from "../components/NextButton";
import SelectWrapper from "../components/SelectWrapper";

export interface PaymentTypeProps {
  name: string;
  key: string;
}

interface PaymentStepProps {
  next: (additional: PaymentTypeProps) => void;
  back: () => void;
  payment: PaymentTypeProps | null;
  finalNextLabel?: string;
  loading?: boolean;
  paymentTypes: PaymentTypeProps[];
}

const PaymentStep: React.FC<PaymentStepProps> = ({
  back,
  next,
  payment,
  children,
  finalNextLabel,
  loading,
  paymentTypes,
}) => {
  const [selected, setSelected] = useState<PaymentTypeProps | null>(payment);

  return (
    <>
      <div className="top-wrapper">
        <Heading>Wie möchtest du später bezahlen?</Heading>
        <SelectWrapper className="grid-big">
          {paymentTypes.map((paymentType) => (
            <Button
              checked={selected?.key === paymentType.key}
              onClick={() => {
                setSelected(paymentType);
              }}
              key={paymentType.key}
            >
              {paymentType.name}
            </Button>
          ))}
        </SelectWrapper>
        {/*
        <DropDown
          items={paymentTypes}
          onSelection={(payment: PaymentTypeProps) => {
            setSelected(payment);
          }}
          label="name"
          keyElement="key"
          selected={selected}
        >
          Wähle deine Zahlungsart
        </DropDown>
        {!!selected ? children : null}
        */}
      </div>
      <NextButton
        onNext={() => {
          next(selected as PaymentTypeProps);
        }}
        onBack={back}
        backDisabled={!!loading}
        nextDisabled={!selected || !!loading}
      >
        {!!loading
          ? "Bitte warten"
          : !selected && !finalNextLabel
          ? "Weiter"
          : finalNextLabel}
      </NextButton>
    </>
  );
};

export default PaymentStep;
