import { DataProps } from "../App";
import { RequestBody } from "../calls/postCase";

const convertToRequestBody = (data: DataProps) => {
  const body = {
    store: data.store?.repairlineIdentifier || "Business Center",
    storeName: data.store?.name,
    storeAddress: data.store?.address,
    symptom:
      (data.defect.defects.length > 0
        ? "Schäden: " +
          data.defect.defects.map((item) => item.title).join(", ") +
          "; "
        : "") +
      data.description +
      "; Zahlungsart: " +
      data.payment?.name +
      (!!data.noSerial ? "; Seriennummer unbekannt" : "") +
      (!!data.device?.noDevice ? "; Gerät unbekannt" : ""),
    type: data.serviceType?.api,
    customer: {
      firstName: data.address.firstName,
      lastName: data.address.lastName,
      companyName: data.address.companyName || undefined,
      phone: data.address.phone,
      email: data.address.mail,
      street: data.address.street,
      streetNumber: data.address.addressNumber,
      zipCode: data.address.zip,
      city: data.address.city,
    },
    device: {
      businessKey: data.device?.businessKey,
      deviceKey: data.device?.repairlineKey || data.device?.deviceKey,
      serialNumber: data.noSerial ? "" : data.serial,
    },
    date: data.date
      ?.toLocaleDateString("de-DE", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
      .replace(", ", ", den "),
    time: data.slot?.slot,
  } as RequestBody;

  if (!data.noInsurance) {
    body.device.insurance = data.insurance?.key;
    body.device.insuranceNumber = data.insuranceNumber;
  }

  if (data.differentDeliveryAddress && data.deliveryAddress) {
    body.deliveryAddress = {
      firstName: data.deliveryAddress.firstName,
      lastName: data.deliveryAddress.lastName,
      companyName: data.deliveryAddress.companyName || undefined,
      phone: data.deliveryAddress.phone,
      email: data.deliveryAddress.mail,
      street: data.deliveryAddress.street,
      streetNumber: data.deliveryAddress.addressNumber,
      zipCode: data.deliveryAddress.zip,
      city: data.deliveryAddress.city,
    };
  }

  if (data.differentInvoiceAddress && data.invoiceAddress) {
    body.deliveryAddress = {
      firstName: data.invoiceAddress.firstName,
      lastName: data.invoiceAddress.lastName,
      companyName: data.invoiceAddress.companyName || undefined,
      phone: data.invoiceAddress.phone,
      email: data.invoiceAddress.mail,
      street: data.invoiceAddress.street,
      streetNumber: data.invoiceAddress.addressNumber,
      zipCode: data.invoiceAddress.zip,
      city: data.invoiceAddress.city,
    };
  }

  if (data.serviceType?.next === "KURIER") {
    body.comment = `Kurierwunsch: ${data.date?.toLocaleDateString("de-DE", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })}, ${data.slot?.slot}`;
  } else if (data.serviceType?.next === "FILIALE") {
    body.comment = `Termin: ${data.date?.toLocaleDateString("de-DE", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })}${!data.noslot ? ", " + data.slot?.slot : ""}`;
  }

  if (!!data.noSerial) {
    if (!!body.comment && body.comment !== "") {
      body.comment = body.comment + "\n\nSeriennummer nachtragen";
    } else {
      body.comment = "Seriennummer nachtragen";
    }
  }

  if (!!data.device?.noDevice) {
    if (!!body.comment && body.comment !== "") {
      body.comment = body.comment + "\n\nGerät nachtragen";
    } else {
      body.comment = "Gerät nachtragen";
    }
  }

  return body;
};

export default convertToRequestBody;
