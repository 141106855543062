import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import AWS from "aws-sdk/global";

AWS.config.region = "eu-central-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "eu-central-1:1ed677c4-e988-4e37-a5e4-5eeee28e83ea",
});

const widgetDivs = document.querySelectorAll(".mtf-add-case");

widgetDivs.forEach((div) => {
  ReactDOM.render(
    <React.StrictMode>
      <App children={div.innerHTML} store={div.getAttribute("data-store")} insurance={div.getAttribute("data-insurance")} />
    </React.StrictMode>,
    div
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
