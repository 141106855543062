interface SelectWrapperProps extends React.HTMLAttributes<HTMLDivElement> {}

const SelectWrapper: React.FC<SelectWrapperProps> = ({
  className = "grid",
  children,
  ...props
}) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default SelectWrapper;
