import clsx from "clsx";
import { ReactComponent as Checkmark } from "../assets/Checkmark.svg";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  checked?: boolean;
  subText?: string | React.ReactElement;
  rightText?: string | React.ReactElement;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  checked,
  subText,
  style,
  rightText,
  disabled,
  ...props
}) => {
  return (
    <button
      className={clsx("mtf-button", { checked }, { "has-subtext": !!subText })}
      style={{
        ...style,
      }}
      {...props}
      disabled={disabled}
    >
      {children}
      {subText ? <p className="subtext">{subText}</p> : null}
      {!!rightText ? (
        <div className="right-text">{rightText}</div>
      ) : checked ? (
        <Checkmark className="checked" />
      ) : null}
    </button>
  );
};

export default Button;
