import { useState } from "react";
import { ReactComponent as Truck } from "./assets/Truck.svg";
import { ReactComponent as Parcel } from "./assets/Parcel.svg";
import { ReactComponent as Idea } from "./assets/Idea.svg";
import DeviceStep, { DeviceProps } from "./steps/DeviceStep";
import DefectStep, { DefectDataProps } from "./steps/DefectStep";
import ServiceTypeStep, { ServiceTypeProps } from "./steps/ServiceTypeStep";
import CustomerAddress, { CustomerAddressDataProps } from "./steps/AddressStep";
import { ReactComponent as Cross } from "./assets/Cross.svg";
import Heading from "./components/Heading";
import distanceInKilometers from "./functions/distanceCalc";
import DefectDetailStep from "./steps/DefectDetailStep";
import PaymentStep, { PaymentTypeProps } from "./steps/PaymentStep";
import AppointmentStep, {
  AppointmentProps,
  AppointmentSlotProps,
  ClosingDaysProps,
} from "./steps/AppointmentStep";
import Tip from "./components/Tip";
import StoreStep, { StoreProps } from "./steps/StoreStep";
import fetchStores from "./calls/fetchStores";
import CategoryStep, { CategoryProps } from "./steps/CategoryStep";
import fetchCategories from "./calls/fetchCategories";
import PreviousCategoryGrid from "./components/PreviousCategoryGrid";
import fetchDevices from "./calls/fetchDevices";
import Button from "./components/Button";
import SerialStep from "./steps/SerialStep";
import sortByField from "./functions/sortByField";
import clsx from "clsx";
import convertToRequestBody from "./functions/convertToRequestBody";
import postCase from "./calls/postCase";
import "./App.css";
import NextButton from "./components/NextButton";
import CaseTypStep from "./steps/CaseTypStep";
import InsuranceStep, { InsuranceProps } from "./steps/InsuranceStep";

const paymentTypes = [
  {
    name: "Bar",
    key: "CA",
  },
  {
    name: "EC",
    key: "EC",
  },
  {
    name: "PayPal",
    key: "PP",
  },
  {
    name: "Kreditkarte",
    key: "CC",
  },
  {
    name: "Überweisung (Vorkasse)",
    key: "EFT",
  },
  {
    name: "Nachnahme",
    key: "COD",
  },
];

const insuranceTypes = [
  {
    name: "HaspaJoker Handy-Tablet-Schutz",
    key: "HASP",
  },
  {
    name: "S-Mobilgeräteschutz",
    key: "SMGS",
  },
  {
    name: "Wertgarantie",
    key: "WRT",
  },
];

export interface DataProps extends AppointmentProps {
  categories: CategoryProps[];
  category: CategoryProps | null;
  device: DeviceProps | null;
  defect: DefectDataProps;
  address: CustomerAddressDataProps;
  deliveryAddress: CustomerAddressDataProps | null;
  invoiceAddress: CustomerAddressDataProps | null;
  differentDeliveryAddress: boolean;
  differentInvoiceAddress: boolean;
  serviceType: ServiceTypeProps | null;
  description: string;
  store: StoreProps | null;
  serial: string;
  storeClosing: number;
  noSerial: boolean;
  noInsurance: boolean;
  caseTyp: "FEE" | "INSURANCE" | null;
  insurance: InsuranceProps | null;
  insuranceNumber: string;
  payment: PaymentTypeProps | null;
}

interface ApiDataProps {
  stores: StoreProps[];
  categories: CategoryProps[];
  devices: DeviceProps[];
  dhl: {
    qr: string;
    label: string;
  };
  error: boolean;
  loading: boolean;
}

const initialDefect = {
  defects: [],
  custom: false,
} as DefectDataProps;

const initialAddress = {
  firstName: "",
  lastName: "",
  companyName: "",
  addressNumber: "",
  additional: "",
  country: "DEU",
  city: "",
  zip: "",
  street: "",
  phone: "",
  mail: "",
  longtitude: null,
  latitude: null,
} as CustomerAddressDataProps;

const blankData = {
  categories: [],
  category: null,
  device: null,
  serial: "",
  defect: initialDefect,
  description: "",
  address: initialAddress,
  payment: null,
  insuranceNumber: "",
  noInsurance: false,
  serviceType: null,
  slot: null,
  store: null,
  date: null,
  storeClosing: 0,
  noSerial: false,
  caseTyp: null,
  insurance: null,
  deliveryAddress: null,
  invoiceAddress: null,
  differentDeliveryAddress: false,
  differentInvoiceAddress: false,
} as DataProps;

const initialApiData = {
  categories: [],
  stores: [],
  devices: [],
  dhl: {
    qr: "",
    label: "",
  },
  error: false,
  loading: false,
};

function App({
  children,
  store,
  insurance,
}: {
  children: string;
  store: string | null;
  insurance: string | null;
}) {
  const baseInsurance = !!insurance
    ? insuranceTypes.find((item) => (item.key = insurance))
    : null;
  const initialData: DataProps = !!baseInsurance
    ? {
        ...blankData,
        caseTyp: "INSURANCE",
        noInsurance: false,
        insurance: baseInsurance,
      }
    : blankData;
  const [state, setState] = useState("START");
  const [data, setData] = useState<DataProps>(initialData);
  const [apiData, setApiData] = useState<ApiDataProps>(initialApiData);

  const goBack = async (removeCount: number, initialData?: any) => {
    const prev = [...data.categories];
    const selected = prev.at(-1 * removeCount);
    prev.splice(-1 * removeCount);
    const result = await fetchCategories(prev.at(-1));
    setData({
      ...data,
      ...initialData,
      categories: prev,
      category: selected || null,
      device: null,
      serviceType: store
        ? {
            title: "Predefined Store",
            subtext: "Defined with data-store attribute",
            next: "",
            api: "bringin",
          }
        : null,
    });
    setApiData({ ...apiData, categories: result.data, error: result.error });
    setState("CATEGORY");
  };

  const previousCategories =
    data.categories.length > 0 ? (
      <>
        <Heading>Gewähltes Gerät</Heading>
        <PreviousCategoryGrid goBack={goBack} categories={data.categories} />
      </>
    ) : null;

  let Stage;

  switch (state) {
    case "CATEGORY":
      Stage = (
        <CategoryStep
          categories={apiData.categories}
          currentCategory={data.category}
          backDisabled={data.categories.length === 0}
          next={async (category: CategoryProps) => {
            if (!category.defaultDevice) {
              const result = await fetchCategories(category);
              setApiData({
                ...apiData,
                categories: result.data,
                error: result.error,
              });
              setData({
                ...data,
                categories: [...data.categories, category],
                category: null,
              });
            } else {
              const result = await fetchDevices(category);
              setApiData({
                ...apiData,
                devices: result.data,
                error: result.error,
              });
              if (result.data.length === 0) {
                setData({
                  ...data,
                  categories: [...data.categories, category],
                  device: {
                    deviceKey: category.defaultDevice as string,
                    repairlineKey: category.defaultDevice as string,
                    name: "Modell unbekannt",
                    noDevice: true,
                    businessKey: category.businessKey as string,
                  },
                });
                setState("SERIAL");
              } else {
                setData({
                  ...data,
                  categories: [...data.categories, category],
                });
                setState("DEVICE");
              }
            }
          }}
          back={() => {
            goBack(1);
          }}
        >
          {previousCategories}
        </CategoryStep>
      );
      break;
    case "DEVICE":
      Stage = (
        <DeviceStep
          next={(device: DeviceProps) => {
            setData({ ...data, device });
            setState("SERIAL");
          }}
          back={() => {
            goBack(1, { device: null });
          }}
          devices={apiData.devices}
          device={data.device}
          category={data.categories.at(-1) as CategoryProps}
        >
          {previousCategories}
        </DeviceStep>
      );
      break;
    case "SERIAL":
      Stage = (
        <SerialStep
          next={(serial: string, noSerial: boolean) => {
            setData({ ...data, serial, noSerial });
            setState("DEFECT");
          }}
          back={() => {
            if (apiData.devices.length > 0) {
              setState("DEVICE");
              setData({ ...data, serial: "", noSerial: false });
            } else {
              goBack(1, { serial: "", noSerial: false });
            }
          }}
          serial={data.serial}
          noSerial={data.noSerial}
        >
          {previousCategories}
          {apiData.devices.length > 0 ? (
            <>
              <Heading>Modell</Heading>
              <div className="grid">
                <Button
                  checked
                  onClick={() => {
                    setState("DEVICE");
                    setData({ ...data, serial: "", noSerial: false });
                  }}
                >
                  {data.device?.name}
                </Button>
              </div>
            </>
          ) : null}
        </SerialStep>
      );
      break;
    case "DEFECT":
      Stage = (
        <DefectStep
          defect={data.defect}
          allowedDefects={data.categories.at(-1)?.tests}
          next={(defect: DefectDataProps) => {
            setData({ ...data, defect });
            setState("DEFECTDETAIL");
          }}
          back={() => {
            setState("SERIAL");
            setData({ ...data, defect: initialDefect });
          }}
        />
      );
      break;
    case "DEFECTDETAIL":
      Stage = (
        <DefectDetailStep
          defect={data.defect}
          description={data.description}
          next={(description: string) => {
            setData({ ...data, description });
            setState("ADDRESS");
          }}
          back={() => {
            setState("DEFECT");
            setData({ ...data, description: "" });
          }}
        />
      );
      break;
    case "ADDRESS":
      Stage = (
        <CustomerAddress
          key="ADDRESS"
          next={(address: CustomerAddressDataProps, differentAddress) => {
            setData({ ...data, ...differentAddress, address });
            if (differentAddress?.differentDeliveryAddress) {
              setState("DELIVERYADDRESS");
            } else if (differentAddress?.differentInvoiceAddress) {
              setState("INVOICEADDRESS");
            } else if (!!baseInsurance) {
              setState("INSURANCE");
            } else {
              setState("CASETYP");
            }
          }}
          back={() => {
            setState("DEFECTDETAIL");
            setData({
              ...data,
              address: initialAddress,
              differentDeliveryAddress: false,
              differentInvoiceAddress: false,
            });
          }}
          address={data.address}
          differentAddress={data}
        >
          <Heading>Deine Kontaktdaten</Heading>
        </CustomerAddress>
      );
      break;
    case "DELIVERYADDRESS":
      Stage = (
        <CustomerAddress
          key="DELIVERYADDRESS"
          next={(address: CustomerAddressDataProps) => {
            setData({ ...data, deliveryAddress: address });
            if (data.differentInvoiceAddress) {
              setState("INVOICEADDRESS");
            } else if (!!baseInsurance) {
              setState("INSURANCE");
            } else {
              setState("CASETYP");
            }
          }}
          back={() => {
            setState("ADDRESS");
            setData({
              ...data,
              deliveryAddress: null,
            });
          }}
          address={data.deliveryAddress || initialAddress}
        >
          <Heading>Deine Lieferanschrift</Heading>
        </CustomerAddress>
      );
      break;
    case "INVOICEADDRESS":
      Stage = (
        <CustomerAddress
          key="INVOICEADDRESS"
          next={(address: CustomerAddressDataProps) => {
            setData({ ...data, invoiceAddress: address });
            if (!!baseInsurance) {
              setState("INSURANCE");
            } else {
              setState("CASETYP");
            }
          }}
          back={() => {
            if (data.differentDeliveryAddress) {
              setState("DELIVERYADDRESS");
            } else {
              setState("ADDRESS");
            }
            setData({
              ...data,
              invoiceAddress: null,
            });
          }}
          address={data.invoiceAddress || initialAddress}
        >
          <Heading>Deine Rechnungsanschrift</Heading>
        </CustomerAddress>
      );
      break;
    case "CASETYP":
      Stage = (
        <CaseTypStep
          next={async (caseTyp: "FEE" | "INSURANCE") => {
            if (caseTyp === "INSURANCE") {
              setData({ ...data, caseTyp, noInsurance: false });
              setState("INSURANCE");
            } else if (store) {
              const result = await fetchStores();
              setApiData({
                ...apiData,
                stores: result.data,
                error: result.error,
              });
              const selectedStore = result.data.find(
                (apiStore: StoreProps) =>
                  apiStore.repairlineIdentifier === store
              );
              if (store) {
                setData({
                  ...data,
                  caseTyp,
                  noInsurance: true,
                  store: selectedStore,
                  serviceType: {
                    title: "Self-Checkin",
                    subtext: "",
                    next: "",
                    api: "bringin",
                  },
                  slot: {
                    slot: "",
                    before: 24,
                  },
                  date: new Date(),
                });
                setState("FILIALEPAYMENT");
              } else {
                setApiData({ ...apiData, error: true });
              }
            } else {
              setData({ ...data, caseTyp, noInsurance: true });
              setState("SERVICE");
            }
          }}
          back={async () => {
            if (data.differentInvoiceAddress) {
              setState("INVOICEADDRESS");
            } else if (data.differentDeliveryAddress) {
              setState("DELIVERYADDRESS");
            } else {
              setState("ADDRESS");
            }
            setData({ ...data, caseTyp: null });
          }}
          caseTyp={data.caseTyp}
        />
      );
      break;
    case "INSURANCE":
      Stage = (
        <InsuranceStep
          next={async (insurance) => {
            if (store) {
              const result = await fetchStores();
              setApiData({
                ...apiData,
                stores: result.data,
                error: result.error,
              });
              const selectedStore = result.data.find(
                (apiStore: StoreProps) =>
                  apiStore.repairlineIdentifier === store
              );
              if (store) {
                setData({
                  ...data,
                  ...insurance,
                  store: selectedStore,
                  slot: {
                    slot: "",
                    before: 24,
                  },
                  date: new Date(),
                });
                setState("FILIALEPAYMENT");
              } else {
                setApiData({ ...apiData, error: true });
              }
            } else {
              setState("SERVICE");
              setData({ ...data, ...insurance });
            }
          }}
          back={async () => {
            if (!!baseInsurance) {
              if (data.differentInvoiceAddress) {
                setState("INVOICEADDRESS");
              } else if (data.differentDeliveryAddress) {
                setState("DELIVERYADDRESS");
              } else {
                setState("ADDRESS");
              }
              setData({ ...data, insuranceNumber: "" });
            } else {
              setState("CASETYP");
              setData({ ...data, insurance: null, insuranceNumber: "" });
            }
          }}
          insurance={data}
          insuranceTypes={insuranceTypes}
          preselectedInsurance={!!insurance}
        />
      );
      break;
    case "SERVICE":
      Stage = (
        <ServiceTypeStep
          insurance={!!baseInsurance}
          serviceType={data.serviceType}
          next={async (serviceType: ServiceTypeProps) => {
            setData({ ...data, serviceType });
            if (serviceType.next === "FILIALE" && apiData.stores.length === 0) {
              const result = await fetchStores();
              setApiData({
                ...apiData,
                stores: result.data,
                error: result.error,
              });
            }
            setState(serviceType.next);
          }}
          back={() => {
            setState(data.caseTyp === "INSURANCE" ? "INSURANCE" : "CASETYP");
            setData({ ...data, serviceType: null });
          }}
          courierDisabled={
            !!data.address.longtitude && !!data.address.latitude
              ? distanceInKilometers(
                  data.address.latitude,
                  data.address.longtitude,
                  53.54476998725198,
                  10.027899998253162
                ) > 15
              : true
          }
        />
      );
      break;
    case "DHL":
      Stage = (
        <PaymentStep
          next={async (payment) => {
            setData({ ...data, payment });
            setApiData({
              ...apiData,
              loading: true,
            });
            const result = await postCase(
              convertToRequestBody({ ...data, payment })
            );
            setApiData({
              ...apiData,
              dhl: {
                label: result.data.dhlLabel,
                qr: result.data.dhlQr,
              },
              error: result.error,
              loading: false,
            });
            setState("DHLEND");
          }}
          back={() => {
            setState("SERVICE");
            setData({
              ...data,
              payment: null,
            });
          }}
          payment={data.payment}
          finalNextLabel="DHL Label erstellen"
          loading={apiData.loading}
          paymentTypes={paymentTypes.filter((item) =>
            ["PP", "EFT", "CC", "COD"].includes(item.key)
          )}
        >
          <Tip heading="Erstelle dein DHL Label" icon={<Parcel />}>
            Du erhälst eine Bestätigungsmail und bekommst ein druckfertiges
            Label.
          </Tip>
        </PaymentStep>
      );
      break;
    case "DHLEND":
      Stage = (
        <>
          <div className="top-wrapper">
            <Heading>Danke für deine Bestellung</Heading>
            <div className="qr-wrapper">
              <img
                className="qr"
                alt="QR Code für DHL"
                src={`data:image/png;base64,${apiData.dhl.qr}`}
              />
              <p className="small-text">
                Zeige den QR Code auf deinem Smartphone in einer Filiale, einem
                Paketshop, bei deiner Zustellkraft, oder an einer DHL
                Packstation vor. DHL druckt das Label für dich aus.
              </p>
            </div>
            <Tip heading="Hinweise zum Versand" icon={<Parcel />}>
              Beachte vor dem Versand deines Gerätes unsere{" "}
              <a href="https://www.justcom.de/informationen-zum-versand/">
                Hinweise zum Versand
              </a>
              .
              <br />
              Du erhälst eine Bestätigungsmail mit einem druckfertigen Label und
              dem QR Code.
            </Tip>
          </div>
          <div className="next-wrapper">
            <a
              href={"data:application/pdf;base64," + apiData.dhl.label}
              download="PaketLabel.pdf"
            >
              <div className="next-button">Lade dein Paket Label herunter</div>
            </a>
            <div className="back-wrapper">
              <button className="back-button" onClick={() => {}} disabled>
                Zurück
              </button>
            </div>
          </div>
        </>
      );
      break;
    case "KURIER":
      Stage = (
        <AppointmentStep
          next={(appointment: AppointmentProps) => {
            setData({ ...data, ...appointment });
            setState("KURIERPAYMENT");
          }}
          back={() => {
            setState("SERVICE");
            setData({ ...data, slot: null, date: null, noslot: undefined });
          }}
          appointment={data}
          blockedNextDays={0}
          closingDays={{ weekday: 17 }}
          slots={{
            weekday: [
              { slot: "10 Uhr - 12 Uhr", before: 10 },
              { slot: "13 Uhr - 15 Uhr", before: 13 },
              { slot: "15 Uhr - 17 Uhr", before: 15 },
              { slot: "17 Uhr - 18 Uhr", before: 17 },
            ],
          }}
        >
          <Heading>Wähle deinen Wunschtermin</Heading>
        </AppointmentStep>
      );
      break;
    case "KURIERPAYMENT":
      Stage = (
        <PaymentStep
          next={async (payment) => {
            setData({ ...data, payment });
            setApiData({
              ...apiData,
              loading: true,
            });
            const result = await postCase(
              convertToRequestBody({ ...data, payment })
            );
            setApiData({
              ...apiData,
              error: result.error,
              loading: false,
            });
            setState("KURIEREND");
          }}
          back={() => {
            setState("KURIER");
            setData({
              ...data,
              payment: null,
            });
          }}
          payment={data.payment}
          finalNextLabel="Kurier buchen"
          loading={apiData.loading}
          paymentTypes={paymentTypes.filter((item) =>
            ["PP", "EFT", "CC"].includes(item.key)
          )}
        >
          <Tip heading="Buche deinen Kurier" icon={<Truck />}>
            Du erhälst eine Bestätigungsmail und wirst von unserem Kundenservice
            kontaktiert. Hier besprechen wir die weiteren Details mit dir.
          </Tip>
        </PaymentStep>
      );
      break;
    case "KURIEREND":
      Stage = (
        <>
          <div className="top-wrapper">
            <Heading>Danke für deine Terminbuchung</Heading>
            <Tip heading="Hinweise zur Abholung" icon={<Idea />}>
              Beachte vor der Abholung deines Gerätes unsere{" "}
              <a href="https://www.justcom.de/informationen-zum-versand/">
                Hinweise zum Versand
              </a>
              .
              <br />
              Du erhälst eine Bestätigungsmail und wirst von unserem
              Kundenservice kontaktiert. Hier besprechen wir die weiteren
              Details mit dir.
            </Tip>
          </div>
          <NextButton
            onNext={() => {
              setState("START");
            }}
            onBack={() => {}}
            backDisabled
          >
            Schließen
          </NextButton>
        </>
      );
      break;
    case "FILIALE":
      Stage = (
        <StoreStep
          next={(store: StoreProps) => {
            let storeClosing = 0;
            switch (new Date().getDay()) {
              case 0:
                storeClosing = store.closing.sunday || 0;
                break;
              case 6:
                storeClosing = store.closing.saturday || 0;
                break;
              default:
                storeClosing = store.closing.weekday;
                break;
            }
            setData({ ...data, store, storeClosing });
            setState("FILIALEAPPOINTMENT");
          }}
          back={async () => {
            if (
              data.serviceType?.next === "FILIALE" &&
              apiData.stores.length === 0
            ) {
              const result = await fetchStores();
              setApiData({
                ...apiData,
                stores: result.data,
                error: result.error,
              });
            }
            setState("SERVICE");
            setData({ ...data, store: null, storeClosing: 0 });
          }}
          stores={
            !!data.address.longtitude && !!data.address.latitude
              ? apiData.stores
                  .map((item) => {
                    const distance = distanceInKilometers(
                      data.address.latitude as number,
                      data.address.longtitude as number,
                      item.coordinates.latitude,
                      item.coordinates.longtitude
                    );

                    return { ...item, distance };
                  })
                  .sort((a, b) => sortByField(a, b, "distance"))
              : apiData.stores
          }
          store={data.store}
        />
      );
      break;
    case "FILIALEAPPOINTMENT":
      Stage = (
        <AppointmentStep
          next={(appointment: AppointmentProps) => {
            setData({ ...data, ...appointment });
            setState("FILIALEPAYMENT");
          }}
          back={() => {
            setState("FILIALE");
            setData({ ...data, slot: null, date: null });
          }}
          appointment={data}
          slots={data.store?.slots as AppointmentSlotProps}
          closing={data.storeClosing}
          closingDays={data.store?.closing as ClosingDaysProps}
          hideSlots
        >
          <Heading>Wähle einen Termin für deine Filiale</Heading>
          <Button
            checked
            subText={
              <>
                {data.store?.address}
                <br />
                {data.store?.openingTimes}
                <br />
                {data.store?.openingTimesSecond}
                <br />
              </>
            }
            style={{ paddingBottom: 0, minHeight: "76px", paddingRight: 86 }}
            onClick={() => {
              setState("FILIALE");
              setData({ ...data, slot: null, date: null });
            }}
            rightText={
              !!data.store?.distance ? (
                <>
                  <p className="right-text-large">
                    {data.store?.distance?.toLocaleString("de-de", {
                      maximumFractionDigits: 1,
                      minimumFractionDigits: 1,
                    })}{" "}
                    km
                  </p>
                  <p>entfernt</p>
                </>
              ) : undefined
            }
          >
            {data.store?.name}
          </Button>
        </AppointmentStep>
      );
      break;
    case "FILIALEPAYMENT":
      Stage = (
        <PaymentStep
          next={async (payment) => {
            setData({ ...data, payment });
            setApiData({
              ...apiData,
              loading: true,
            });
            const result = await postCase(
              convertToRequestBody({ ...data, payment })
            );
            setApiData({
              ...apiData,
              error: result.error,
              loading: false,
            });
            setState("FILIALEEND");
          }}
          back={() => {
            if (store) {
              setState(data.caseTyp === "INSURANCE" ? "INSURANCE" : "CASETYP");
              setData({ ...data, payment: null });
            } else {
              setState("FILIALEAPPOINTMENT");
              setData({
                ...data,
                payment: null,
              });
            }
          }}
          payment={data.payment}
          finalNextLabel={store ? "Fall anlagen" : "Termin bestätigen"}
          loading={apiData.loading}
          paymentTypes={paymentTypes.filter((item) =>
            ["CA", "EC", "CC"].includes(item.key)
          )}
        ></PaymentStep>
      );
      break;
    case "FILIALEEND":
      Stage = (
        <>
          <div className="top-wrapper">
            <Heading>Danke für deine Terminbuchung</Heading>
            <Tip heading="Du bist fertig" icon={<Idea />}>
              Du erhältst eine Mail mit deinem Termin und der Adresse der
              Filiale, Wir freuen uns auf dich.
            </Tip>
            <Heading>Wusstest du schon</Heading>
            <p className="small-text">
              In unseren Filialen haben wir eine Menge Zubehör, wie Ladekabel
              und Handyhüllen für viele Geräte. Sprich einfach unsere Techniker
              vor Ort an und lass dich beraten.
            </p>
          </div>
          <NextButton
            onNext={() => {
              setState("START");
            }}
            onBack={() => {}}
            backDisabled
          >
            Schließen
          </NextButton>
        </>
      );
      break;
    default:
      Stage = (
        <>
          <div
            className={clsx("top-wrapper", "no-overflow")}
            dangerouslySetInnerHTML={{ __html: children }}
          ></div>
          <div className="next-wrapper">
            <button
              className="start-button"
              onClick={async () => {
                setApiData(initialApiData);
                setData(initialData);
                const result = await fetchCategories();
                setApiData({
                  ...apiData,
                  categories: result.data,
                  error: result.error,
                });
                setState("CATEGORY");
              }}
            >
              REPARATUR BEAUFTRAGEN
            </button>
          </div>
        </>
      );
      break;
  }

  return (
    <div
      className={clsx("App", {
        "App-start": state === "START",
        "App-progress": state !== "START",
      })}
    >
      {state !== "START" ? (
        <button
          className="mtf-close"
          onClick={() => {
            setState("START");
            setData(initialData);
          }}
        >
          <Cross />
        </button>
      ) : null}
      {apiData.error ? (
        <Heading>Ein Fehler ist aufgetreten, versuche es später erneut</Heading>
      ) : (
        Stage
      )}
    </div>
  );
}

export default App;
