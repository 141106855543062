import { useState } from "react";
import AutoComplete from "../components/AutoComplete";
import Button from "../components/Button";
import Heading from "../components/Heading";
import NextButton from "../components/NextButton";
import Tip from "../components/Tip";
import { CategoryProps } from "./CategoryStep";

export interface DeviceProps {
  name: string;
  deviceKey: string;
  repairlineKey: string;
  noDevice?: boolean;
  businessKey: string;
}

interface DeviceStepProps {
  back: () => void;
  next: (device: DeviceProps) => void;
  devices: DeviceProps[];
  device: DeviceProps | null;
  category: CategoryProps;
}

const DeviceStep: React.FC<DeviceStepProps> = ({
  back,
  next,
  devices,
  device,
  category,
  children,
}) => {
  const [selected, setSelected] = useState<DeviceProps | null>(device);

  return (
    <>
      <div className="top-wrapper">
        {children}
        <Heading>Modell</Heading>
        {!!selected && !selected.noDevice ? (
          <Button
            checked
            onClick={() => {
              setSelected(null);
            }}
          >
            {selected.name}
          </Button>
        ) : (
          <AutoComplete
            disabled={selected?.noDevice}
            placeholder="Gerätename"
            items={devices}
            onSelection={(item) => {
              setSelected(
                !!item ? { ...item, businessKey: category.businessKey } : null
              );
            }}
          />
        )}
        <Tip style={{ marginTop: 35 }} heading="Denk dran">
          Wenn du dir nicht sicher bist, welches Modell du hast, finden wir es
          für dich raus. Trage in diesem Fall nur ein, was du weisst.
        </Tip>
        <Button
          style={{ marginTop: 13 }}
          checked={selected?.noDevice}
          onClick={() => {
            setSelected(
              !selected?.noDevice
                ? {
                    deviceKey: category.defaultDevice as string,
                    repairlineKey: category.defaultDevice as string,
                    name: "Modell unbekannt",
                    noDevice: true,
                    businessKey: category.businessKey as string,
                  }
                : null
            );
          }}
        >
          Ich kenne mein Modell nicht
        </Button>
      </div>
      <NextButton
        onNext={() => {
          next(selected as DeviceProps);
        }}
        onBack={back}
        nextDisabled={!selected}
      >
        Weiter
      </NextButton>
    </>
  );
};

export default DeviceStep;
