import { useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import NextButton from "../components/NextButton";
import SelectWrapperSingle from "../components/SelectWrapperSingle";
import Tip from "../components/Tip";

export interface ServiceTypeProps {
  title: string;
  subtext: string;
  next: string;
  api: string;
}

interface ServiceTypeStepProps {
  serviceType: ServiceTypeProps | null;
  next: (serviceTyp: ServiceTypeProps) => void;
  back: () => void;
  courierDisabled?: boolean;
  insurance?: boolean;
}

const ServiceTypeStep: React.FC<ServiceTypeStepProps> = ({
  back,
  next,
  serviceType,
  courierDisabled,
  insurance,
}) => {
  const [selected, setSelected] = useState<ServiceTypeProps | null>(
    serviceType
  );

  const pickupOptions = [];

  pickupOptions.push({
    title: "DHL - Kostenfreie Einsendung",
    subtext: "Wir übernehmen die Kosten deiner Einsendung und der Rücksendung",
    next: "DHL",
    api: "parcel",
  });
  if (!insurance) {
    pickupOptions.push({
      title: "Kurier-Abholung",
      subtext:
        "Wir senden unseren Kurier an deine Adresse in Hamburg, um dein Gerät abzuholen. Durch die persönliche Abholung entstehen zusätzliche Kosten.",
      next: "KURIER",
      api: "delivery",
    });
  }
  pickupOptions.push({
    title: "Abgabe in Justcom Filiale",
    subtext: "Wir sind in unseren Hamburger Filialen für dich da",
    next: "FILIALE",
    api: "bringin",
  });

  return (
    <>
      <div className="top-wrapper">
        <Heading>Wie möchtest du dein Gerät zu uns bringen?</Heading>
        <SelectWrapperSingle>
          {pickupOptions.map((item) => (
            <Button
              checked={item.next === selected?.next}
              key={item.next}
              subText={item.subtext}
              onClick={() => {
                setSelected(item);
              }}
              disabled={courierDisabled && item.next === "KURIER"}
            >
              {item.title}
            </Button>
          ))}
        </SelectWrapperSingle>
        {courierDisabled ? (
          <Tip heading="Hinweis">
            Du befindest dich leider außerhalb unseres Kurier Lieferradius.
            Nutze den kostenfreien Versand mit DHL oder gib dein Gerät in einer
            unserer Filialen ab.
          </Tip>
        ) : null}
      </div>
      <NextButton
        onNext={() => {
          next(selected as ServiceTypeProps);
        }}
        onBack={back}
        nextDisabled={!selected}
      >
        Weiter
      </NextButton>
    </>
  );
};

export default ServiceTypeStep;
