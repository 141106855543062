interface Address {
  salutation?: "Frau" | "Herr" | "Divers";
  firstName: string;
  lastName: string;
  companyName?: string;
  department?: string;
  phone: string;
  email: string;
  street: string;
  streetNumber: string;
  additional?: string;
  zipCode: string;
  city: string;
}

interface DeviceType {
  businessKey: string;
  deviceKey: string;
  name: string;
  serialNumber: string;
  insurance?: string;
  insuranceNumber?: string;
}

export interface RequestBody {
  store: string;
  storeName: string;
  storeAddress: string;
  symptom: string;
  type: "bringin" | "delivery" | "parcel";
  customer: Address;
  deliveryAddress?: Address;
  invoiceAddress?: Address;
  device: DeviceType;
  comment?: string;
  date: string;
  time: string;
}

const postCase = async (requestBody: RequestBody) => {
  try {
    const raw = await fetch(
      `https://ipyzqcvg36.execute-api.eu-central-1.amazonaws.com/prod/case`,
      {
        method: "POST",
        body: JSON.stringify(requestBody),
      }
    );

    if (raw.status > 299) return { data: [], error: true };

    const result = await raw.json();

    return {
      data: result,
      error: false,
    };
  } catch (err) {
    console.log(err);
    return { data: [], error: true };
  }
};

export default postCase;
