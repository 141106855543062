import { useMemo, useState } from "react";
import Input from "./Input";
import Location from "aws-sdk/clients/location";
import { v4 as uuid } from "uuid";
import useFocus from "../hooks/useFocus";
import debounce from "lodash/debounce";

interface ButtonProps extends React.HTMLProps<HTMLInputElement> {
  checked?: boolean;
  disabled?: boolean;
  onSelection: (item: Location.Place) => void;
  setKeyword: (text: string) => void;
  keyword: string;
}

const AddressComplete: React.FC<ButtonProps> = ({
  children,
  checked,
  disabled,
  onSelection,
  keyword,
  setKeyword,
  ...props
}) => {
  const [results, setResults] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [inputRef, setInputFocus] = useFocus();

  const location = new Location({ apiVersion: "2020-11-19" });
  let current = "";

  const clear = () => {
    setOpen(false);
  };

  const handleClick = (item: string) => {
    setOpen(false);
    location.searchPlaceIndexForText(
      {
        IndexName: "explore.place.db",
        Text: item,
        BiasPosition: [10.0049, 53.5627],
        FilterCountries: ["DEU"],
        MaxResults: 1,
      },
      (err, data) => {
        onSelection(data.Results[0].Place);
      }
    );
  };

  const onSearch = (text: string) => {
    if (text !== "" && text.length > 2) {
      const id = uuid();
      current = id;
      location.searchPlaceIndexForSuggestions(
        {
          IndexName: "explore.place",
          Text: text,
          BiasPosition: [10.0049, 53.5627],
          FilterCountries: ["DEU"],
        },
        (err, data) => {
          if (err) {
            console.log(err);
          } else if (id === current) {
            setResults(data.Results.map((item) => item.Text));
            setOpen(true);
          }
        }
      );
    } else {
      setResults([]);
      clear();
    }
  };

  const debouncedFetch = useMemo(() => debounce(onSearch, 300), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    if (focused) debouncedFetch(event.target.value);
  };

  const cancelSearch = () => {
    setKeyword("");
    clear();
    (setInputFocus as () => void)();
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {keyword.length > 0 && focused ? (
          <button className="autocomplete-clear" onClick={cancelSearch}>
            x
          </button>
        ) : null}
        <Input
          {...props}
          disabled={disabled}
          style={{
            ...(disabled ? { backgroundColor: "#d9d9d9" } : {}),
          }}
          value={keyword}
          onChange={handleChange}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={async () =>
            setTimeout(() => {
              setFocused(false);
            }, 200)
          }
          ref={inputRef}
        />
        {focused}
        {open && focused ? (
          <div className="autocomplete-select-wrapper">
            {results.slice(0, 5).map((item, index) => (
              <button
                className="autocomplete-select"
                key={item + index}
                onClick={() => {
                  handleClick(item);
                }}
              >
                {item}
              </button>
            ))}
            {results.length === 0 ? (
              <div className="autocomplete-missing">
                Wir haben die Adresse leider nicht gefunden
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AddressComplete;
