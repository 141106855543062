import NextButton from "../components/NextButton";
import Calendar from "react-calendar";
import { ReactComponent as Back } from "../assets/Back.svg";
import { useState } from "react";
import { isHoliday } from "feiertagejs";
import Button from "../components/Button";
import SelectWrapper from "../components/SelectWrapper";

interface SlotProps {
  slot: string;
  before: number;
}

export interface AppointmentSlotProps {
  weekday: SlotProps[];
  saturday?: SlotProps[];
  sunday?: SlotProps[];
}

export interface AppointmentProps {
  date: Date | null;
  slot: SlotProps | null;
  noslot?: boolean;
}

export interface ClosingDaysProps {
  weekday: number;
  saturday?: number;
  sunday?: number;
}

interface AppointmentStepProps {
  next: (appointment: AppointmentProps) => void;
  back: () => void;
  appointment: AppointmentProps;
  slots: AppointmentSlotProps;
  blockedNextDays?: number;
  closing?: number;
  closingDays: ClosingDaysProps;
  hideSlots?: boolean;
}

const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const dateFromToday = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);

  return date;
};

const availableSlots = (date: Date | null, slots: AppointmentSlotProps) => {
  if (!date) return [] as SlotProps[];

  let filteredSlots;

  switch (date.getDay()) {
    case 0:
      filteredSlots = slots.sunday;
      break;
    case 6:
      filteredSlots = slots.saturday;
      break;
    default:
      filteredSlots = slots.weekday;
      break;
  }

  if (isToday(date)) {
    const now = new Date().getHours();

    return filteredSlots?.filter((item) => item.before > now) as SlotProps[];
  }
  return filteredSlots as SlotProps[];
};

const AppointmentStep: React.FC<AppointmentStepProps> = ({
  next,
  back,
  appointment,
  slots,
  blockedNextDays,
  closing,
  closingDays,
  children,
  hideSlots,
}) => {
  const [selected, setSelected] = useState(appointment);

  let minDate;
  if (!!blockedNextDays) {
    minDate = dateFromToday(blockedNextDays);
  } else if (!!closing) {
    minDate = new Date().getHours() < closing ? new Date() : dateFromToday(1);
  } else {
    minDate =
      (availableSlots(new Date(), slots) || []).length > 0
        ? new Date()
        : dateFromToday(1);
  }

  return (
    <>
      <div className="top-wrapper">
        {children}
        <Calendar
          minDetail="month"
          next2Label={null}
          prev2Label={null}
          prevLabel={<Back />}
          nextLabel={<Back style={{ transform: "rotate(180deg)" }} />}
          locale="de-de"
          minDate={minDate}
          maxDate={dateFromToday(42)}
          tileDisabled={({ date }) =>
            (date.getDay() === 0 &&
              ((closing && !closingDays.sunday) ||
                (!closing && (slots.sunday || []).length === 0))) ||
            (date.getDay() === 6 &&
              ((closing && !closingDays.saturday) ||
                (!closing && (slots.saturday || []).length === 0))) ||
            isHoliday(date, "HH")
          }
          value={selected.date}
          onClickDay={(value) => {
            setSelected(
              hideSlots
                ? {
                    ...selected,
                    date: value,
                    slot: { slot: "", before: 24 },
                    noslot: true,
                  }
                : { ...selected, date: value }
            );
          }}
        />
        {hideSlots ? null : (
          <SelectWrapper>
            {availableSlots(selected.date, slots).map((item) => (
              <Button
                checked={item.slot === selected.slot?.slot}
                key={item.slot}
                style={{ paddingBottom: 0, minHeight: "76px" }}
                onClick={() => {
                  setSelected({ ...selected, slot: item, noslot: false });
                }}
              >
                {item.slot}
              </Button>
            ))}
            {!!selected.date && closing !== undefined ? (
              <Button
                checked={selected.noslot}
                style={{ paddingBottom: 0, minHeight: "76px" }}
                onClick={() => {
                  setSelected({
                    ...selected,
                    slot: { slot: "", before: 24 },
                    noslot: true,
                  });
                }}
              >
                Ich komme ohne Termin vorbei
              </Button>
            ) : null}
          </SelectWrapper>
        )}
      </div>
      <NextButton
        onNext={() => {
          next(selected);
        }}
        onBack={back}
        nextDisabled={
          !selected.date ||
          !selected.slot ||
          (selected.slot.slot === "" && !selected.noslot)
        }
      >
        Weiter
      </NextButton>
    </>
  );
};

export default AppointmentStep;
