import { CategoryProps } from "../steps/CategoryStep";
import Button from "./Button";

interface PreviousCategoryGridProps {
  categories: CategoryProps[];
  goBack: (back: number) => void;
}

const PreviousCategoryGrid: React.FC<PreviousCategoryGridProps> = ({
  goBack,
  categories,
}) => {
  return (
    <div className="grid">
      {categories.map((element, index) => (
        <Button
          checked
          key={element.categoryKey}
          onClick={() => {
            goBack(categories.length - index);
          }}
        >
          {element.category}
        </Button>
      ))}
    </div>
  );
};

export default PreviousCategoryGrid;
