import { useState } from "react";
import Location from "aws-sdk/clients/location";
import Input from "../components/Input";
import Label from "../components/Label";
import AddressComplete from "../components/AddressComplete";
import NextButton from "../components/NextButton";
import { isValidPhoneNumber } from "libphonenumber-js";
import Button from "../components/Button";

export interface CustomerAddressProps {
  firstName: string;
  lastName: string;
  companyName: string;
  addressNumber: string;
  additional: string;
  country: string;
  city: string;
  zip: string;
  street: string;
  phone: string;
  mail: string;
}

export interface CustomerAddressDataProps extends CustomerAddressProps {
  longtitude?: number | null;
  latitude?: number | null;
}

export interface DifferentAddressProps {
  differentDeliveryAddress: boolean;
  differentInvoiceAddress: boolean;
}

interface CustomerAddressStepProps {
  next: (
    address: CustomerAddressDataProps,
    differentAddress?: DifferentAddressProps
  ) => void;
  back: () => void;
  address: CustomerAddressDataProps;
  differentAddress?: DifferentAddressProps;
}

const CustomerAddress: React.FC<CustomerAddressStepProps> = ({
  children,
  back,
  next,
  address,
  differentAddress: differentAddressBase,
}) => {
  const [customer, setCustomer] = useState(address);
  const [differentAddress, setDifferentAddress] =
    useState(differentAddressBase);
  const location = new Location({ apiVersion: "2020-11-19" });

  const onSelection = (data: Location.Place) => {
    const cust = { ...customer };
    if (data.AddressNumber) cust.addressNumber = data.AddressNumber;
    if (data.Country) cust.country = data.Country;
    if (data.Municipality) cust.city = data.Municipality;
    if (data.PostalCode) cust.zip = data.PostalCode;
    if (data.AddressNumber) cust.addressNumber = data.AddressNumber;
    if (data.Street) cust.street = data.Street;

    setCustomer(cust);
  };

  const validateMail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validatePhone = (phone: string) => {
    try {
      return isValidPhoneNumber(phone, "DE");
    } catch (err) {
      return false;
    }
  };

  const valid =
    customer.firstName !== "" &&
    customer.lastName !== "" &&
    customer.addressNumber !== "" &&
    customer.city !== "" &&
    customer.zip !== "" &&
    customer.zip.length === 5 &&
    customer.street !== "" &&
    customer.phone !== "" &&
    validatePhone(customer.phone) &&
    customer.mail !== "" &&
    validateMail(customer.mail);

  const onNext = () => {
    location.searchPlaceIndexForText(
      {
        IndexName: "explore.place",
        Text: [
          customer.street,
          customer.addressNumber,
          customer.zip,
          customer.city,
          customer.country,
        ].join(", "),
        BiasPosition: [10.0049, 53.5627],
        FilterCountries: ["DEU"],
        MaxResults: 1,
      },
      (err, data) => {
        if (err) {
          console.log(err);
        } else {
          const place = data.Results[0]?.Place.Geometry.Point;
          next(
            {
              ...customer,
              ...(!!place ? { longtitude: place[0], latitude: place[1] } : {}),
            },
            differentAddress
          );
        }
      }
    );
  };

  return (
    <>
      <div className="top-wrapper">
        {children}
        <div className="grid-spread">
          <div>
            <Label>Vorname*</Label>
            <Input
              value={customer.firstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCustomer({ ...customer, firstName: event.target.value });
              }}
              autoComplete="given-name"
              name="fname"
            />
          </div>
          <div>
            <Label>Nachname*</Label>
            <Input
              value={customer.lastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCustomer({ ...customer, lastName: event.target.value });
              }}
              autoComplete="family-name"
              name="lname"
            />
          </div>
        </div>
        <Label>Unternehmen</Label>
        <Input
          value={customer.companyName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomer({ ...customer, companyName: event.target.value });
          }}
          autoComplete="organization"
          name="companyName"
        />
        <div className="grid-spread">
          <div>
            <Label>Straße*</Label>
            <AddressComplete
              onSelection={onSelection}
              setKeyword={(text) => {
                setCustomer({ ...customer, street: text });
              }}
              keyword={customer.street}
              autoComplete="off"
              name="street-address"
            />
          </div>
          <div>
            <Label>Nr*</Label>
            <Input
              value={customer.addressNumber}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCustomer({ ...customer, addressNumber: event.target.value });
              }}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="grid-spread">
          <div>
            <Label>PLZ*</Label>
            <Input
              value={customer.zip}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCustomer({ ...customer, zip: event.target.value });
              }}
              autoComplete="postal-code"
              name="zip"
            />
          </div>
          <div>
            <Label>Ort*</Label>
            <Input
              value={customer.city}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCustomer({ ...customer, city: event.target.value });
              }}
              autoComplete="address-level2"
              name="city"
            />
          </div>
        </div>
        <Label>Telefon*</Label>
        <Input
          value={customer.phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomer({ ...customer, phone: event.target.value });
          }}
          autoComplete="tel"
          name="phone"
        />
        <Label>Mail*</Label>
        <Input
          value={customer.mail}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomer({ ...customer, mail: event.target.value });
          }}
          autoComplete="email"
          name="email"
        />
        {differentAddress !== undefined ? (
          <>
            <Button
              style={{ marginTop: 39 }}
              checked={differentAddress.differentDeliveryAddress === true}
              onClick={() => {
                setDifferentAddress({
                  ...differentAddress,
                  differentDeliveryAddress:
                    !differentAddress.differentDeliveryAddress,
                });
              }}
            >
              Abweichende Lieferanschrift
            </Button>
            <Button
              style={{ marginTop: 13 }}
              checked={differentAddress.differentInvoiceAddress === true}
              onClick={() => {
                setDifferentAddress({
                  ...differentAddress,
                  differentInvoiceAddress:
                    !differentAddress.differentInvoiceAddress,
                });
              }}
            >
              Abweichende Rechnungsanschrift
            </Button>
          </>
        ) : null}
      </div>
      <NextButton onNext={onNext} nextDisabled={!valid} onBack={back}>
        Weiter
      </NextButton>
    </>
  );
};

export default CustomerAddress;
