import { useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import NextButton from "../components/NextButton";
import SelectWrapper from "../components/SelectWrapper";

interface CaseTypStepProps {
  next: (caseTyp: "INSURANCE" | "FEE") => void;
  back: () => void;
  caseTyp: "INSURANCE" | "FEE" | null;
}

const CaseTypStep: React.FC<CaseTypStepProps> = ({ back, next, caseTyp }) => {
  const [selected, setSelected] = useState<"INSURANCE" | "FEE" | null>(caseTyp);

  return (
    <>
      <div className="top-wrapper">
        <Heading>Wähle deinen Servicefall aus</Heading>
        <SelectWrapper className="grid-big">
          <Button
            checked={selected === "FEE"}
            onClick={() => {
              setSelected("FEE");
            }}
            subText="Du bekommst von uns einen Kostenvoranschlag und entscheidest dann über die Reparatur"
          >
            kostenpflichtige Reparatur
          </Button>
          <Button
            checked={selected === "INSURANCE"}
            onClick={() => {
              setSelected("INSURANCE");
            }}
            subText="Du hast bereits eine Schadensnummer für einen Fall bei uns erhalten"
          >
            Versicherungsfall
          </Button>
        </SelectWrapper>
      </div>
      <NextButton
        onNext={() => {
          next(selected as "INSURANCE" | "FEE");
        }}
        onBack={back}
        nextDisabled={!selected}
      >
        Weiter
      </NextButton>
    </>
  );
};

export default CaseTypStep;
