import { useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import Input from "../components/Input";
import NextButton from "../components/NextButton";
import SelectWrapper from "../components/SelectWrapper";

export interface InsuranceProps {
  name: string;
  key: string;
}

export interface InsuranceDataProps {
  insuranceNumber: string;
  insurance: InsuranceProps | null;
}

interface InsuranceStepProps {
  next: (insurance: InsuranceDataProps) => void;
  back: () => void;
  insurance: InsuranceDataProps;
  insuranceTypes: InsuranceProps[];
  preselectedInsurance?: boolean;
}

const InsuranceStep: React.FC<InsuranceStepProps> = ({
  back,
  next,
  insurance,
  insuranceTypes,
  preselectedInsurance
}) => {
  const [selected, setSelected] = useState<InsuranceDataProps>(insurance);

  return (
    <>
      <div className="top-wrapper">
        {!preselectedInsurance ? (
          <>
            <Heading>Wähle deine Versicherung aus</Heading>
            <SelectWrapper className="grid-big">
              {insuranceTypes.map((insuranceData) => (
                <Button
                  checked={selected.insurance?.key === insuranceData.key}
                  onClick={() => {
                    setSelected({ ...selected, insurance: insuranceData });
                  }}
                  key={insuranceData.key}
                >
                  {insuranceData.name}
                </Button>
              ))}
            </SelectWrapper>
          </>
        ) : null }
        
        <Heading>Schadensnummer</Heading>
        <Input
          value={selected.insuranceNumber}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSelected({ ...selected, insuranceNumber: event.target.value });
          }}
          autoComplete="off"
          disabled={!selected.insurance}
        />
      </div>
      <NextButton
        onNext={() => {
          next(selected as InsuranceDataProps);
        }}
        onBack={back}
        nextDisabled={!selected.insurance || selected.insuranceNumber === ""}
      >
        Weiter
      </NextButton>
    </>
  );
};

export default InsuranceStep;
