import { useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import Input from "../components/Input";
import NextButton from "../components/NextButton";

interface SerialStepProps {
  back: () => void;
  next: (serial: string, noSerial: boolean) => void;
  serial: string;
  noSerial: boolean;
}

const SerialStep: React.FC<SerialStepProps> = ({
  back,
  next,
  serial,
  children,
  noSerial,
}) => {
  const [input, setInput] = useState({ serial, noSerial });

  return (
    <>
      <div className="top-wrapper">
        {children}
        <Heading>Seriennummer / IMEI</Heading>
        <Input
          value={input.serial}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, serial: event.target.value });
          }}
          autoComplete="off"
          disabled={!!input.noSerial}
        />
        <Button
          style={{ marginTop: 13 }}
          checked={input?.noSerial}
          onClick={() => {
            setInput({ serial: "", noSerial: !input.noSerial });
          }}
        >
          Ich kenne meine Seriennummer nicht
        </Button>
      </div>
      <NextButton
        onBack={back}
        onNext={() => {
          next(input.serial, input.noSerial);
        }}
        nextDisabled={input.serial === "" && !input.noSerial}
      />
    </>
  );
};

export default SerialStep;
