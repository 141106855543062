import React, { useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import NextButton from "../components/NextButton";
import SelectWrapper from "../components/SelectWrapper";

const defectOptions = [
  {
    key: "DISP",
    title: "Display",
    subtext:
      "Risse im Display, Anzeigefehler im Display oder sonstige Defekte im Display",
  },
  {
    key: "AKKU",
    title: "Akku",
    subtext: "Akku entlädt schnell oder Handy lässt sich nicht starten",
  },
  {
    key: "CAM",
    title: "Kamera",
    subtext:
      "Das Kameraglas ist kaputt, Keine Möglichkeit mehr Fotos zu machen oder ein anderer Defekt",
  },
  {
    key: "PORT",
    title: "Buchsen",
    subtext: "Lorem ipsum",
  },
  {
    key: "LIQ",
    title: "Flüssigkeit",
    subtext: "Lorem ipsum",
  },
  {
    key: "DATA",
    title: "Daten",
    subtext: "Lorem ipsum",
  },
  {
    key: "FUNC",
    title: "keine Funktion",
    subtext: "Lorem ipsum",
  },
];

export interface DefectProps {
  key: string;
  title: string;
  subtext: string;
}

export interface DefectDataProps {
  defects: DefectProps[];
  custom: boolean;
}

interface DefectStepProps {
  next: (defect: DefectDataProps) => void;
  back: () => void;
  defect: DefectDataProps;
  allowedDefects?: string[];
}

const DefectStep: React.FC<DefectStepProps> = ({
  defect,
  back,
  next,
  allowedDefects = [],
}) => {
  const [selected, setSelected] = useState(defect);

  return (
    <>
      <div className="top-wrapper">
        <Heading>Was ist kaputt?</Heading>
        <SelectWrapper>
          {defectOptions
            .filter((item) => allowedDefects.includes(item.key))
            .map((item) => (
              <Button
                checked={selected.defects.includes(item)}
                key={item.title}
                onClick={() => {
                  selected.defects.includes(item)
                    ? setSelected({
                        ...selected,
                        defects: selected.defects.filter((ele) => ele !== item),
                      })
                    : setSelected({
                        ...selected,
                        defects: [...selected.defects, item],
                      });
                }}
                {
                  /*subText={item.subtext}*/ ...{}
                }
              >
                {item.title}
              </Button>
            ))}
          <Button
            checked={selected.custom}
            onClick={() => {
              setSelected({ ...selected, custom: !selected.custom });
            }}
            {
              /*subText="Du kannst deinen Defekt im nächsten Schritt kurz beschreiben"*/ ...{}
            }
          >
            Anderer Defekt
          </Button>
        </SelectWrapper>
      </div>
      <NextButton
        onBack={back}
        onNext={() => {
          next(selected);
        }}
        nextDisabled={selected.defects.length === 0 && !selected.custom}
      />
    </>
  );
};

export default DefectStep;
