import { useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import NextButton from "../components/NextButton";
import SelectWrapper from "../components/SelectWrapper";
import { AppointmentSlotProps } from "./AppointmentStep";

interface StoreStepProps {
  stores: StoreProps[];
  store: StoreProps | null;
  next: (serviceTyp: StoreProps) => void;
  back: () => void;
}

export interface StoreProps {
  name: string;
  repairlineIdentifier: string;
  slots: AppointmentSlotProps;
  address: string;
  coordinates: {
    longtitude: number;
    latitude: number;
  };
  closing: {
    weekday: number;
    saturday?: number;
    sunday?: number;
  };
  openingTimes: string;
  openingTimesSecond: string;
  distance?: number;
}

const StoreStep: React.FC<StoreStepProps> = ({ back, next, stores, store }) => {
  const [selected, setSelected] = useState<StoreProps | null>(store);

  return (
    <>
      <div className="top-wrapper">
        <Heading>Wähle eine unserer Filialen</Heading>
        <SelectWrapper className="grid-big">
          {stores.map((item) => (
            <Button
              checked={
                !!selected &&
                selected.repairlineIdentifier === item.repairlineIdentifier
              }
              key={item.repairlineIdentifier}
              subText={
                <>
                  {item.address}
                  <br />
                  {item.openingTimes}
                  <br />
                  {item.openingTimesSecond}
                  <br />
                </>
              }
              style={{ paddingBottom: 0, minHeight: "76px", paddingRight: 86 }}
              onClick={() => {
                setSelected(item);
              }}
              rightText={
                !!item.distance ? (
                  <>
                    <p className="right-text-large">
                      {item.distance.toLocaleString("de-de", {
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 1,
                      })}{" "}
                      km
                    </p>
                    <p>entfernt</p>
                  </>
                ) : undefined
              }
            >
              {item.name}
            </Button>
          ))}
        </SelectWrapper>
      </div>
      <NextButton
        onNext={() => {
          next(selected as StoreProps);
        }}
        onBack={back}
        nextDisabled={!selected}
      >
        Weiter
      </NextButton>
    </>
  );
};

export default StoreStep;
