interface SelectWrapperSingleProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const SelectWrapperSingle: React.FC<SelectWrapperSingleProps> = ({
  className = "single-column",
  children,
  ...props
}) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default SelectWrapperSingle;
