import { String } from "aws-sdk/clients/appstream";
import React, { useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import NextButton from "../components/NextButton";
import TextArea from "../components/TextArea";
import Tip from "../components/Tip";
import { DefectDataProps } from "./DefectStep";

interface DefectDetailStepProps {
  next: (text: String) => void;
  back: () => void;
  defect: DefectDataProps;
  description: string;
}

const DefectDetailStep: React.FC<DefectDetailStepProps> = ({
  defect,
  description,
  back,
  next,
}) => {
  const [input, setInput] = useState(description);

  return (
    <>
      <div className="top-wrapper">
        <Heading>Was ist kaputt?</Heading>
        <div className="grid">
          {defect.defects.map((item) => (
            <Button
              key={item.title}
              checked
              onClick={back}
              {
                /*subText={item.subtext}*/ ...{}
              }
            >
              {item.title}
            </Button>
          ))}
          {defect.custom ? (
            <Button
              checked
              onClick={back}
              {
                /*subText="Du kannst deinen Defekt im nächsten Schritt kurz beschreiben"*/ ...{}
              }
            >
              Anderer Defekt
            </Button>
          ) : null}
        </div>
        <Heading>Beschreibe deinen Defekt</Heading>
        <TextArea
          rows={5}
          maxLength={500}
          value={input}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setInput(event.target.value);
          }}
          autoComplete="off"
        />
        <Tip heading="Denk dran">
          Du hast jederzeit die Möglichkeit die Reparatur abzulehnen. In diesem
          Fall bezahlst du maximal 19,90€ für die Analysekosten
        </Tip>
      </div>
      <NextButton
        onBack={back}
        onNext={() => {
          next(input);
        }}
        nextDisabled={input === ""}
      />
    </>
  );
};

export default DefectDetailStep;
