import { ReactComponent as Idea } from "../assets/Idea.svg";

interface TipProps extends React.HTMLAttributes<HTMLDivElement> {
  heading: string;
  icon?: React.ReactNode;
}

const Tip: React.FC<TipProps> = ({ children, heading, style, icon }) => {
  return (
    <div className="tip" style={style}>
      <div className="icon" style={!icon ? { marginTop: 0 } : {}}>
        {!icon ? (
          <Idea
            style={{
              transform: "rotate(15deg)",
              marginRight: "6px",
              width: "68px",
              minWidth: "68px",
            }}
          />
        ) : (
          icon
        )}
      </div>
      <div>
        <p className="heading">{heading}</p>
        {children}
      </div>
    </div>
  );
};

export default Tip;
