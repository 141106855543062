import { useEffect, useState } from "react";
import useFocus from "../hooks/useFocus";
import Input from "./Input";

interface ButtonProps extends React.HTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  items: any[];
  disabled?: boolean;
  onSelection: (item: any | null) => void;
  value?: string;
}

const AutoComplete: React.FC<ButtonProps> = ({
  children,
  checked,
  items,
  disabled,
  onSelection,
  ...props
}) => {
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [finished, setFinished] = useState(false);
  const [inputRef, setInputFocus] = useFocus();

  const clear = () => {
    setOpen(false);
    onSelection(null);
    setFinished(false);
  };

  useEffect(() => {
    setKeyword("");
    setOpen(false);
    setFinished(false);
  }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (item: any) => {
    setKeyword(item.name);
    setOpen(false);
    onSelection(item);
    setFinished(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSearch(event.target.value);
  };

  const matchName = (name: string, keyword: string) => {
    return name.toLowerCase().includes(keyword.toLowerCase());
  };

  const onSearch = (text: string) => {
    if (text !== "") {
      const resultList = items.filter((item) => matchName(item.name, text));
      setResults(resultList);
      setOpen(true);
      onSelection(null);
      setFinished(false);
    } else {
      setResults([]);
      clear();
    }
  };

  const cancelSearch = () => {
    setKeyword("");
    clear();
    (setInputFocus as () => void)();
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {keyword.length > 0 ? (
          <button className="autocomplete-clear" onClick={cancelSearch}>
            x
          </button>
        ) : null}
        <Input
          {...props}
          disabled={disabled}
          autoFocus
          style={{
            ...(finished
              ? {
                  borderColor: "#42A72B",
                  color: "#42A72B",
                }
              : {}),
          }}
          value={keyword}
          onChange={handleChange}
          autoComplete="off"
          ref={inputRef}
        />
        {open ? (
          <div className="autocomplete-select-wrapper">
            {results.slice(0, 5).map((item) => (
              <button
                className="autocomplete-select"
                key={item.deviceKey}
                onClick={() => {
                  handleClick(item);
                }}
              >
                {item.name}
              </button>
            ))}
            {results.length === 0 ? (
              <div className="autocomplete-missing">
                Wir haben leider kein solches Gerät gefunden
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AutoComplete;
