import { useEffect, useState } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import NextButton from "../components/NextButton";
import SelectWrapper from "../components/SelectWrapper";

export interface CategoryProps {
  categoryKey: string;
  category: string;
  defaultDevice?: string;
  businessKey?: string;
  tests?: string[];
  sort?: number;
}

interface CategoryStepProps {
  back: () => void;
  next: (category: CategoryProps) => void;
  categories: CategoryProps[];
  currentCategory?: CategoryProps | null;
  backDisabled?: boolean;
}

const CategoryStep: React.FC<CategoryStepProps> = ({
  back,
  next,
  categories,
  currentCategory,
  children,
  backDisabled,
}) => {
  const [selected, setSelected] = useState(currentCategory);

  useEffect(() => {
    setSelected(currentCategory);
  }, [currentCategory]);

  return (
    <>
      <div className="top-wrapper">
        {children}
        <Heading>Wähle dein Gerät</Heading>
        <SelectWrapper>
          {categories.map((item) => (
            <Button
              checked={item.categoryKey === selected?.categoryKey}
              key={item.categoryKey}
              onClick={() => {
                setSelected(item);
              }}
            >
              {item.category}
            </Button>
          ))}
        </SelectWrapper>
      </div>
      <NextButton
        onNext={() => {
          next(selected as CategoryProps);
          setSelected(null);
        }}
        onBack={back}
        nextDisabled={!selected}
        backDisabled={backDisabled}
      >
        Weiter
      </NextButton>
    </>
  );
};

export default CategoryStep;
