import sortByField from "../functions/sortByField";
import { CategoryProps } from "../steps/CategoryStep";

const fetchDevices = async (category: CategoryProps) => {
  try {
    const raw = await fetch(
      `https://ipyzqcvg36.execute-api.eu-central-1.amazonaws.com/prod/device?categoryKey=${category.categoryKey}`
    );

    if (raw.status > 299) return { data: [], error: true };

    const result = await raw.json();

    return {
      data: result?.response.sort((a: any, b: any) =>
        sortByField(a, b, "name")
      ),
      error: false,
    };
  } catch (err) {
    console.log(err);
    return { data: [], error: true };
  }
};

export default fetchDevices;
